.nav .active {
    filter: drop-shadow(0px 0px 127px rgba(241, 241, 241, 0.2)) drop-shadow(0px 0px 74.3773px rgba(241, 241, 241, 0.151852))
        drop-shadow(0px 0px 40.4519px rgba(241, 241, 241, 0.121481)) drop-shadow(0px 0px 20.6375px rgba(241, 241, 241, 0.1))
        drop-shadow(0px 0px 10.3481px rgba(241, 241, 241, 0.0785185)) drop-shadow(0px 0px 4.99768px rgba(241, 241, 241, 0.0481481));
}

.headerShadow {
    filter: drop-shadow(0px 7px 69px rgba(0, 0, 0, 0.06)) drop-shadow(0px 2.6963px 21.9778px rgba(0, 0, 0, 0.0364444))
        drop-shadow(0px 0.57037px 5.62222px rgba(0, 0, 0, 0.0235556));
}

.homeInput {
    box-shadow: 0px 9px 27px -321px rgba(129, 127, 127, 0.17), 0px 1.8px 4.3875px -321px rgba(129, 127, 127, 0.085);
}

.hidescroll::-webkit-scrollbar {
    display: none;
}
#progress_review {
    border-radius: 2px;
    height: 1px;
    width: 100%;
}
#progress_review::-webkit-progress-bar {
    /* background-color: #f2f2f250; */
    background-color: transparent;
    border-radius: 2px;
}
#progress_review::-webkit-progress-value {
    background-color: white;
    border-radius: 2px;
}
#progress_review::-moz-progress-bar {
    /* style rules */
}
.height-review {
    min-height: calc(100vh - 64px - 56px);
    overflow-y: scroll;
}
#control-height {
    height: 100vh;
    width: 0;
    position: absolute;
}

.min-height {
    height: calc(100% - 48px);
}

.color-fill-white {
    --color_fill: rgb(255, 255, 255);
}

.rotate {
    animation: animatedshrukin 0.8s ease-in-out;
}

@keyframes animatedshrukin {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

* {
    -webkit-tap-highlight-color: transparent; /* transparent with keyword */
}
